﻿import "../Styles/index.scss";
import { ScrollWatcher } from "./scroll-watcher";
import { Fancybox } from "@fancyapps/ui";
import Cookies from "js-cookie";
import SvelteTag from "@wagich/svelte-tag";

Fancybox.bind(".asset.is-zoomable", {
	groupAttr: "data-gallery",
});
Fancybox.bind("[data-fancybox]");

// NAVIGATION

let navOverlay = document.querySelector<HTMLElement>("#navOverlay")!;
document.querySelector("#openNav")?.addEventListener("click", e => {
	navOverlay.classList.add("is-active");
});
document.querySelector("#closeNav")?.addEventListener("click", e => {
	navOverlay.classList.remove("is-active");
});

let expandedElement: HTMLElement | null = null;
for (let element of document.querySelectorAll<HTMLElement>(".nav-item:has(.nav-list.level-2) .nav-link.level-1")) {
	element.addEventListener("click", e => {
		let target = element.parentElement!.querySelector<HTMLElement>(".nav-list-wrapper.level-2");
		if (expandedElement != target) {
			expandedElement?.classList.remove("is-expanded");
		}
		target?.classList.toggle("is-expanded");

		expandedElement = target;
		e.preventDefault();
	})
}

// NOTIFICATIONS

function measureNotifications(notifications: HTMLElement) {
	let notificationItems = notifications.querySelector<HTMLElement>(".notifications__items");
	if (notificationItems == null) {
		return;
	}
	notificationItems.style.maxHeight = "none";
	document.body.style.setProperty("--notifications-height", `${notificationItems.clientHeight + 32}px`);
	notificationItems.style.removeProperty("max-height");
}

function closeNotifications() {
	document.body.classList.remove("is-notifications-active");
	isNotificationsActive = false;
	Cookies.set("notificationsClosed", "true");
}

const notifications = document.querySelector<HTMLElement>("#notifications");
let isNotificationsActive = document.body.classList.contains("is-notifications-active");
if (notifications != null) {
	window.addEventListener("resize", () => measureNotifications(notifications));
	measureNotifications(notifications);

	document.querySelector("#openNotifications")?.addEventListener("click", e => {
		document.body.classList.add("is-notifications-active");
		isNotificationsActive = true;
	});
	document.querySelector("#closeNotifications")?.addEventListener("click", e => {
		closeNotifications();
	});
}

// LOGO ANIMATION
const headerLogo = document.querySelector("#headerLogo");
if (headerLogo != null) {
	let isHeaderLogoCollapsed = false;
	let isMobileQuery = window.matchMedia("(max-width: 768px)");
	new ScrollWatcher((dir, x) => {
		if (!isHeaderLogoCollapsed && dir == "down" && x > 60) {
			headerLogo.classList.add("is-collapsed");
			isHeaderLogoCollapsed = true;
		}
		if (isHeaderLogoCollapsed && dir == "up" && x < 60) {
			headerLogo.classList.remove("is-collapsed");
			isHeaderLogoCollapsed = false;
		}
		if (isNotificationsActive && dir == "down" && x > 60 && !isMobileQuery.matches) {
			closeNotifications();
		}
	});
}

// CUSTOM ELEMENTS

if (document.querySelector("custom-form") != null) {
	import("./custom-form/custom-form.svelte").then(m => {
		new SvelteTag({
			component: m.default,
			tagname: "custom-form",
			attributes: ["form-id", "visibility", "metadata"]
		});
	});
}

if (document.querySelector("bluc-map") != null) {
	import("./bluc-map.svelte").then(m => {
		new SvelteTag({
			component: m.default,
			tagname: "bluc-map",
			attributes: ["longitude", "latitude", "zoom", "accesstoken", "style"]
		});
	});
}
