﻿import { debounce } from "lodash";

export type ScrollDirection = "up" | "down";
export type Callback = (direction: ScrollDirection, x: number) => void;

export class ScrollWatcher {
    private toleranceInPx: number;
	private callback: Callback;
	private lastScrollTop = 0;

	constructor(callback: Callback, toleranceInPx: number = 10) {
		this.callback = callback;
		this.toleranceInPx = toleranceInPx;
		window.addEventListener("scroll", this.onScrollDebounced, { passive: true });
	}

	onScrollDebounced = debounce(this.onScroll.bind(this), 50);
	private onScroll() {
		let currentScrollTop = window.scrollY;

		if (Math.abs(this.lastScrollTop - currentScrollTop) > this.toleranceInPx) {
			let scrollDirection: ScrollDirection = currentScrollTop < this.lastScrollTop ? "up" : "down";
			this.callback(scrollDirection, currentScrollTop);

			this.lastScrollTop = currentScrollTop;
		}
    }
}
